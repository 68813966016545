import logo from './logo.svg';
import './App.css';

import InWorkIcon from './Components/InWorkIcon.js'; 

function App() {
  return (
    <div className="App">
      <h1 className = "inWork">Wonderful Photography will be displayed here soon. Please be patient as the grandson robots are hard at work constructing the site!</h1>
      <InWorkIcon/> 
    </div>
  );
}

export default App;
